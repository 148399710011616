



.cards-heading {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .cards-heading p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .card-para {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .card-para p {
    font-family: 'Rubik';
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #e0e0e0;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .comp-logo {
    flex-basis: 200px;
    max-height: 105px;
    padding: 20px;
  }
  
  .comp-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }



.comp-carousel-reverse, .comp-carousel {
  overflow: hidden;
}

.comp-carousel div {
  height: 140px;
  width: calc(4188px * 3);
  background: url('../../infScrollComp/oneWay.png') repeat-x;
  animation: slide 60s linear infinite;
}

.comp-carousel-reverse div {
  height: 140px;
  width: calc(4188px * 3);
  background: url('../../infScrollComp/otherWay.png') repeat-x;
  animation: slide-rev 60s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-4188px, 0, 0); /* The image width */
  }
}

@keyframes slide-rev {
  0% {
    transform: translate3d(-4188px, 0, 0); /* The image width */
  }
  100% {
    transform: translate3d(0, 0, 0); /* The image width */
  }
}
  
  @media screen and (max-width: 600px) {
    .plans-card {
      flex-direction: column;
      padding: 10px 53px;
    }
  }
  
#fullpage {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: 90%;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }

  .escape-fullpage {
        background-color: transparent;
        color: white;
        border: none;
        padding: 10px;
        font-size: 25px;
        position: fixed;
        z-index: 10000;
        right: 50px;
        top: 10px;
  }

  .react-transform-component {
    width: 100vw !important;
    height: 100vh !important;
    justify-content: center !important;
    align-items: center !important;
  }
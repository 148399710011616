.test {
  color: greenyellow;
}

.af-container-main {
  padding-top: 65px;
}

.AF-container {
  padding: 0;
  font-family: 'Rubik';
  background-color: #08223F;
  margin-bottom: 20px;
  display: flex;
  color: white;
  padding-right: 20px;
  position: relative;
}

.AF-container .AF-heading {
  margin-top: 25px;
  margin-left: -190px;
}

.AF-container h1 {
  text-transform: uppercase;
  font-size: 24px;
}

.AF-container p {
  font-size: 16px;
}

.AF-container .level {
  display: flex;
  align-items: center;
}

.AF-container .bg-image {
  width: 50%;
}

.AF-container .level h4 {
  padding-right: 21px;
  color: #FFF;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
}

.AF-container .level ul {
  color: #FFF;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  border-left: 1px solid white;
  text-decoration: none;
  list-style: none;
  padding-left: 21px;
}

.AF-container .level ul li {
  margin: 7px 0;
}

.AF-container .level ul li:first-child {
  margin-top: 0;
}

.AF-container .level ul li:last-child {
  margin-bottom: 0;
}

.AF-container .level {
  margin-top: 40px;
}

.AF-container .level:nth-child(3n + 2) {
  margin-left: -100px;
}

.AF-container .level:nth-child(3n + 3) h4, 
.AF-container .level:nth-child(3n + 3) li {
  font-weight: 500 !important;
}


.AF-container .level:nth-child(3n + 1) h4, 
.AF-container .level:nth-child(3n + 1) li {
  font-weight: 900 !important;
}

.AF-container .level:nth-child(3n + 1) {
  margin-left: 100px;
}

.AF-container > div {
  padding-bottom: 20px;
}

.AF-container .go-to-affiliate {
  position: absolute;
  font-size: 16px;
  right: 40px;
  bottom: 40px;
  padding: 10px 15px;
  height: fit-content;
  border: none;
  background-color: white;
  color: black;
  text-decoration: none;
  transition: .3s ease-in-out;
}

.AF-container .go-to-affiliate:hover {
  background-color: #06182C;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .AF-container .AF-heading {
    margin-top: 25px;
    margin-left: -150px;
  }  

  .AF-container .go-to-affiliate {
    bottom: calc(50% - 44px);
  }
}
@media only screen and (max-width: 992px) {
  .AF-container .go-to-affiliate {
    position: unset;
    margin-top: 10px;
    margin-bottom: 50px;
    width: 200px;
    text-align: center;
    text-transform: uppercase;
  }
  .AF-container h1 {
    font-size: 20px;
  }

  .AF-container h4 {
    font-size: 15px !important;
    padding-right: 15px !important;
  }
  .AF-container ul {
    font-size: 15px !important;
    padding-left: 15px !important;
  }

  .AF-container .AF-heading {
    margin-top: 25px;
    margin-left: 0px;
  }  
  .AF-container {
    padding-left: 20px;
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .AF-container > div {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AF-container .level {
    margin-left: 0 !important;
  }

  .AF-container .bg-image {
      display: none;
    }
}

.heading p{
    margin-top: 160px;
    font-weight: 700;
    font-size: 40px;
    color: white;
    font-family: 'Rubik', sans-serif;

}

.rightArrow {
    vertical-align: baseline;
    margin-left: 10px;
    animation: idleArrow infinite 1.5s;
}

.rightArrow path {
    fill: white;
}

@keyframes idleArrow {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(7px);
    }
    100% {
        transform: translateX(0px);
    }
}

.para p{
    max-width: 600px;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}
.circle{
    background-color: white;
    display: grid;
    place-items: center;
    padding: 5px;
    border-radius: 50%;
    margin-top: 2px;
}
.vector{
    width: 5.99px;
    height: 10.02px;
}
.btn p{
    font-size: 18px;
}

.image img{
    width: 100%;
    margin-top: 10%;
}
.btnd {
    background: transparent;
    /* background: linear-gradient(89.71deg, #10CA83 1.95%, #10CA83 99.75%); */
    outline: none;
    border-radius: 40px;
    border: 2px solid #10CA83;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    /* font-weight: 500; */
    transition: .4s;
  }

.hero-block {
    margin-top: 65px;
    background-color: 	#08223F;
    height: 60vh;   
    display: flex;
    justify-content: space-between;
}

.hero-text {
    margin-left: calc((100vw - 1320px) / 2);
}

.hero-block-main  {
    color: black !important;
    
}

.hero-image {
    width: 50vw;
    height: 60vh !important;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}

  .btnd:hover {
    transform: translateY(-3px);
    background: #10CA83;
  }



@media only screen and (max-width: 1400px) {
    .img-container-custom {
    }
    
    .hero-text {
        margin-left: calc((100vw - 1140px) / 2);
    }

    .hero-block { 
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 992px) {
    .hero-block {
        margin-top: 150px;
        flex-direction: column-reverse;
    }

    .hero-text {
        margin: 30px auto;
        max-width: 720px;
        margin-left: calc((100vw - 720px) / 2) !important;
    }

    .heading p {
        margin-top: 10px;
    }

    .hero-image {
        width: 100%;
        clip-path: none;
    }

    /* .hero-text {
        margin-left: calc((100vw - 960px) / 2);
    } */

}


@media only screen and (max-width: 1200px) {
    
    .hero-text {
        margin-left: calc((100vw - 960px) / 2);
    }

}

@media screen and (max-width:992px) {
    .text{
        text-align: center;
    }
    .btns{
        margin: auto;
        width: 45%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .btnd{
        margin: auto;
        width: 45%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}



@media only screen and (max-width: 768px) {
    img {
        object-fit: cover;
    }

    .hero-block {
        margin-top: 50px;
        flex-direction: column-reverse;
    }

    .hero-text p {
        font-size: 16px;
    }

    .hero-text {
        margin: 10px !important;
    }
}

.logo-img{
    width: auto;
    height: 50px;
}

.nav-link {
    color: white !important;
    font-family: 'Rubik' !important;
    border-bottom: 2px solid transparent;
    padding-bottom: 12px !important;
}

.nav .container {
    align-items: flex-end !important;
    padding-top: 5px;
}

.nav-link:hover {
    color: #10CA83 !important;
    border-bottom: 2px solid #10CA83;
    font-family: 'Rubik' !important;
}

.nav {
    background-color: #08223F;
    position: fixed !important;
    width: 100%;
    z-index: 200;
    top: 0;
    padding: 0 !important;
    -webkit-box-shadow: 0px 3px 31px -18px rgba(6,24,44,1);
    -moz-box-shadow: 0px 3px 31px -18px rgba(6,24,44,1);
    box-shadow: 0px 3px 31px -18px rgba(6,24,44,1);
}


.logo-src {
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbar-toggler {
    border: none !important;
    margin-bottom: 9px;
    background-color: transparent;
}

.navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
}

.navbar-toggler .navbar-toggler-icon {
    background-image: url("../../assets/menu.svg");
}

.heading-2 p {
  height: 54px;
  /* font-family: 'Rubik'; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
}

.heading-2 {
  margin-bottom: 20px;
}
.para-2 p {
  font-family: 'Poppins', sans-serif;
  width: 60%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #e0e0e0;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-align: center;
}
.para-2 {
  width: 100%;
  display: grid;
  place-items: center;
}
.one {
  width: 100%;
  min-height: 450px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  padding: 60px 10px;
  border-radius: 10px;
  transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  /* width: 500px; */
}


.banner {
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #10CA83;
  color: white;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  top: -2px;
  left: -2px;
}

.card-cont:nth-child(3n+2) {
  transform: scale(1.1);
}

.one::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  border-radius: 10px;
  width: 100%; height: 100%;
  background-size: cover;
  filter: grayscale(100%);
  opacity: 0.2;
  transition: .3s;
}

.card-cont:nth-child(3n + 1) .one::before {
  background-image: url('../../planBgs/football.jpg');
  background-position: center right;
}

.one:hover::before {
  
  opacity: 0.35;
  filter: grayscale(20%);
}

.card-cont:nth-child(3n + 2) .one::before {
  background-image: url('../../planBgs/footballStadium.jpg');
}

.card-cont:nth-child(3n + 3) .one::before {
  background-image: url('../../planBgs/rugby.jpg');
}


.heading-3 h2{
  z-index: 1;
  position: relative;
  font-family: 'Inter';
  font-style: normal;
  font-size: 21px;
  line-height: 54px;
  text-transform: capitalize;
  color: #ffffff;
}
.vector-2 {
  background-color: white;
  border-top-right-radius: 10px;
  position: absolute;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  padding: 10px 14px 60px 60px;
  right: -2px;
  z-index: 40;
  top: -2px;
}

.vector-2 img {
  width: 20px;
}

.para-3 {
  margin-top: 65px !important;
}
.para-3 span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ffffff;  
  z-index: 1;
  position: relative;
}

.para-4 span {
  display: block;
  font-style: normal;
  font-family: 'Inter';
  font-size: 14px;
  text-align: center;
  z-index: 1;
  position: relative;
  text-transform: capitalize;
  color: #ffffffd5;
}
.para-5 p {
  margin-top: 7px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: oblique;
  font-weight: 1000;
  font-size: 74px;
  line-height: 54px;
  z-index: 1;
  position: relative;
  letter-spacing: -0.05em;
  text-transform: capitalize;
  color: #ffffff;
}
.para-5 p sup {
  margin-left: -10px;
  font-size: 50px;
  z-index: 1;
  position: relative;
}
.btn-4 {
  background: linear-gradient(89.71deg, #10ca83 1.95%, #10ca83 99.75%);
  border-radius: 20px;
  /* padding: 10px 50px; */
  padding: 2% 22%;
  outline: none;
  border: none;
  margin-top: -7%;
  font-family: 'Inter';
}
.col-5 {
  box-sizing: border-box !important;
}
.tabs {
  display: flex;
  justify-content: center;
}

.plan-section {
  gap: 50px;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .plan-section {
    gap: 20px;
  }
  .card-cont {
    width: 30%;
  }

  .para-5 p {
    font-size: 50px;
  }
  .para-5 p sup {
    font-size: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .card-cont:nth-child(3n+2) {
    transform: none;
  }
}

@media only screen and (max-width: 450px) {
  .container-fluid-no-padding {
    padding: 0;
    padding-left: 0px;
  }

  .card-custom {
    margin-top: 0 !important;
    display: flex;
    flex-direction: row;
    width: 920px;
    gap: 0px;
  }
  
  .card-cont-2 {
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .card-cont-2::-webkit-scrollbar {
    display: none;
  }

  .card-cont {
    width: 300px;
    margin: 0;
    scroll-snap-align: start;
  }

  .btnd {
    width: 60%;
  }
}
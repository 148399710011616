
.gallery-heading h2{
    height: 28px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.gallery-para p{
    font-family: 'Rubik';
    width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #E0E0E0;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.clickss{
 color: white;
  background: #F3F3F31A;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
    
}

.img-container {
    /* width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px; */

      /**
   * Lay out the children of this container with
   * flexbox, which is horizontal by default.
   */
  display: flex;

  /**
   * Set the main axis to be vertical instead of
   * horizontal, so now the children are laid out
   * vertically, from top to bottom.
   */
  flex-direction: column;

  /**
   * Now when the children wrap, they'll wrap to the
   * next "column".
   */
  flex-wrap: wrap;

  width: 100%;
    gap: 20px;
  overflow: auto;
}

.img-container img {
    /* flex: 1;
    width: 30%;
    object-fit: contain; */
}
.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    cursor: pointer;
}

.slide img {
    max-height: 450px;
}

.selected {
    background-color: transparent !important;
}

.tes img {
    object-fit: contain;
}

.links{
    width: 228px;
    box-sizing: border-box;
    font-family: 'Poppins';
    cursor: pointer;
    font-size: 16px;
}
.links-one{
    width: 100%;
    border-radius: 10px 0 0 10px;
    font-family: 'Poppins';
    cursor: pointer;

}
.links-last{
    width: 100%;
    border-radius: 0 10px 10px 0;
    font-family: 'Poppins';
    cursor: pointer;

}
.links p , .links-last p , .links-one p{
    cursor: pointer;
}
.col-1 img{
    width: 120px;
    height: 84px;
}

.gallerys{
    display: grid;
    grid-template-columns: repeat(9,1fr);
    grid-template-rows: repeat(5,1fr);
    gap: 10px;
}

.tiki{
    display: grid;
    place-items: center;
}

.alpha-logo {
    flex-basis: 200px;
    max-height: 105px;
    padding: 20px;
  }
  
  .alpha-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

.selected-tiket{

    background-color: #10CA83;

}



@media screen and (max-width: 600px) {
    .gallery-card {
      flex-direction: column;
      padding: 10px 53px;
    }
    #fullpage-img {
        width: 100%;
    }

    .escape-fullpage {
        right: 21px !important;
    }

    .carousel {
        height: 400px !important;
    }

    .slider-wrapper {
        height: 400px !important;
    }

    #Prosli-tiketi .thumbs-wrapper {
        display: none;
      }
  }
